import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Search",
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/Search.vue")
  },
  {
    path: "/enrolled",
    name: "Enrolled",
    component: () =>
      import(/* webpackChunkName: "timetable" */ "../views/Enrolled.vue")
  },
  {
    path: "/timetable",
    name: "TimeTable",
    component: () =>
      import(/* webpackChunkName: "timetable" */ "../views/TimeTable.vue")
  },
  {
    path: "/advanced-timetable",
    name: "AdvancedTimeTable",
    component: () =>
      import(
        /* webpackChunkName: "advancedtimetable" */ "../views/AdvancedTimeTable.vue"
      )
  },
  {
    path: "/submitted",
    name: "Submitted",
    component: () =>
      import(/* webpackChunkName: "submitted" */ "../views/Submitted.vue")
  },
  {
    path: "/attendance",
    name: "Attendance",
    component: () => import("../views/Attendance.vue")
  },
  {
    path: "/attendance/:courseCode",
    name: "AttendanceWithCode",
    component: () => import("../views/Attendance.vue")
  },
  {
    path: "/attendance/:courseCode/today",
    name: "Attendance",
    props: { attendanceToday: true },
    component: () => import("../views/Attendance.vue")
  },
  {
    path: "/attendance/:courseCode/date/:attendanceId",
    name: "AttendanceDetail",
    component: () => import("../views/Attendance.vue")
  },
  {
    path: "/attendance/:courseCode/student/:studentId",
    name: "AttendanceStudent",
    component: () => import("../views/Attendance.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
