<template>
  <v-container>
    <v-card class="mx-auto" elevation="8" width="60%" :loading="showLoading">
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="headline mb-1">
            Googleアカウントでログイン
          </v-list-item-title>
          <v-list-item-subtitle>
            @oe.jcfl.ac.jpアカウントのみ有効
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          class="ma-2"
          color="secondary"
          @click="googleLogin"
          :disabled="showLoading"
          >ログイン</v-btn
        ></v-card-actions
      >
    </v-card>
  </v-container>
</template>
<script>
import firebase from "firebase";

export default {
  name: "Login",
  data() {
    return {
      msg: "ようこそ、JCFL OE ポータルへ"
    };
  },
  computed: {
    showLoading() {
      return this.$store.state.nowAuthAcction;
    }
  },
  methods: {
    googleLogin: function() {
      firebase
        .auth()
        .signInWithRedirect(new firebase.auth.GoogleAuthProvider());
    }
  }
};
</script>
