import firebase from "firebase";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyAvzuFX0FJSyfdSDj-lTTIJLruyigLiEXk",
  // authDomain: "oe-portal.firebaseapp.com",
  authDomain: "portal.oe.jcfl.ac.jp",
  projectId: "oe-portal",
  storageBucket: "oe-portal.appspot.com",
  messagingSenderId: "1077355291611",
  appId: "1:1077355291611:web:86976968948e04b1f9fdfe",
  measurementId: "G-QJ3HKH1Q8G"
};
firebase.initializeApp(config);

// const stagingConfig = {
//   apiKey: "AIzaSyAegx5_nTgZcKAM6N39HUTjAlSBeOlMiJ8",
//   // authDomain: "dev-oe-portal.firebaseapp.com",
//   authDomain: "dev-oe-portal.web.app",
//   projectId: "dev-oe-portal",
//   storageBucket: "dev-oe-portal.appspot.com",
//   messagingSenderId: "914036659265",
//   appId: "1:914036659265:web:b3def24e96b05a0da162c0",
//   measurementId: "G-HHTG461927"
// };
// firebase.initializeApp(stagingConfig);

export const functions = firebase.functions();
