<template>
  <div>
    <span v-show="!isLogin">ログインしていません</span>
    <span v-show="isLogin"
      >{{ userEmail }}
      <v-tooltip bottom v-if="showTeacherIcon">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            fab
            v-bind="attrs"
            v-on="on"
            :color="getTeacherIconColor"
          >
            <v-icon>mdi-human-male-board</v-icon></v-btn
          >
        </template>
        <span>{{ getTeacherIconMsg }}</span>
      </v-tooltip>
      /
      {{ className
      }}<v-icon v-if="canEdit" @click="editClassName">mdi-account-edit</v-icon>
      /
      <v-btn @click="logout">ログアウト</v-btn></span
    >
  </div>
</template>

<script>
// import firebase from "firebase";

export default {
  name: "PersonalBar",

  components: {},

  data: () => ({
    //
  }),
  computed: {
    isLogin() {
      return !!this.$store.state.loginUser;
    },
    userEmail() {
      const loginUser = this.$store.state.loginUser;
      return loginUser ? loginUser.email : null;
    },
    className: {
      get() {
        const name = this.$store.state.loginUser?.className;
        if (name != null) {
          return name;
        } else {
          return "未定義";
        }
      },
      set(value) {
        this.$store.dispatch("setClassName", value);
      }
    },
    canEdit() {
      return !!this.$store.state.loginUser?.className;
    },
    hasCoordinator() {
      const userType = this.$store.state.userType;
      if (userType === "coordinator") {
        return true;
      } else {
        return false;
      }
    },
    hasTeacher() {
      const userType = this.$store.state.userType;
      if (userType === "teacher") {
        return true;
      } else {
        return false;
      }
    },
    showTeacherIcon() {
      return this.hasCoordinator || this.hasTeacher;
    },
    getTeacherIconColor() {
      if (this.hasCoordinator) {
        return "orange";
      } else if (this.hasTeacher) {
        return "pink";
      } else {
        return "primary";
      }
    },
    getTeacherIconMsg() {
      if (this.hasCoordinator) {
        return "専任講師";
      } else if (this.hasTeacher) {
        return "非常勤講師";
      } else {
        return "学生または未設定";
      }
    }
  },
  methods: {
    logout: function() {
      this.$store.dispatch("doLogout");
      this.$router.push({
        name: "Search",
        params: {}
      });
    },
    editClassName() {
      this.$store.commit("EDOT_USER_CLASS_NAME");
    }
  }
};
</script>
