export class Classroom {
  constructor(
    courseId,
    enrollmentCode,
    courseState,
    courseCode,
    name,
    section,
    descriptionHeading,
    description,
    room,
    className,
    teacherId,
    teacherName,
    coordinatorId,
    coordinatorName,
    credit,
    numberOfStudents,
    url,
    schedules,
    students,
    showOrder,
    parentCourseCode = null,
    attendance = false,
    isCS = true
  ) {
    this.courseId = courseId;
    this.enrollmentCode = enrollmentCode;
    this.courseState = courseState;
    this.courseCode = courseCode;
    this.name = name;
    this.section = section;
    this.descriptionHeading = descriptionHeading;
    this.description = description;
    this.room = room;
    this.className = className;
    this.teacherId = teacherId;
    this.teacherName = teacherName;
    this.coordinatorId = coordinatorId;
    this.coordinatorName = coordinatorName;
    this.credit = credit;
    this.numberOfStudents = numberOfStudents;
    this.url = url;
    this.schedules = schedules;
    this.students = students;
    this.showOrder = showOrder;
    this.parentCourseCode = parentCourseCode;
    this.attendance = attendance;
    this.isCS = isCS;
  }
  get viewName() {
    return `${this.name}(${this.courseCode})`;
  }
  get inviteUrl() {
    if (this.url) {
      return `${this.url}?hl=ja&cjc=${this.enrollmentCode}`;
    } else {
      return null;
    }
  }
  get searchableText() {
    if (this.parentCourseCode) {
      return `${this.courseCode} ${this.name} ${this.className} ${this.teacherName} ${this.parentCourseCode}`.toLowerCase();
    } else {
      return `${this.courseCode} ${this.name} ${this.className} ${this.teacherName}`.toLowerCase();
    }
  }
  get showOrderList() {
    return this.schedules.map(s => {
      return parseInt(s.weekday + s.period);
    });
  }
}

export class User {
  constructor(
    uid,
    email,
    type = null,
    name = null,
    enName = null,
    kana = null,
    active = true,
    className = null
  ) {
    this.uid = uid;
    this.email = email;
    this.type = type;
    this.name = name;
    this.enName = enName;
    this.kana = kana;
    this.active = active;
    this.className = className;
    this.modified = false;
  }
  get viewName() {
    if (this.className) {
      return `${this.email}(${this.className})`;
    } else {
      return `${this.email}`;
    }
  }
  get getName() {
    if (this.name) {
      return `${this.name} / ${this.enName}`;
    } else {
      return this.email;
    }
  }
  get nameOrEmail() {
    if (this.name) {
      return this.name;
    } else {
      return this.email;
    }
  }
  get sortedName() {
    if (this.enName) {
      return this.enName.toUpperCase();
    } else {
      return `ZZZ ${this.email}`;
    }
  }
}

export class Submitted {
  constructor(courseId, select1, select2, select3, message, modified = true) {
    this.courseId = courseId;
    this.select1 = select1;
    this.select2 = select2;
    this.select3 = select3;
    this.message = message;
    this.modified = modified;
  }
}

// 出欠
export class AttendanceStatus {
  constructor(
    studentId,
    status,
    late = false,
    left = false,
    fixed = false,
    sent = false,
    comment = null
  ) {
    this.studentId = studentId;
    this.status = status;
    this.late = late;
    this.left = left;
    this.fixed = fixed;
    this.sent = sent;
    this.comment = comment;
  }
}
export class AttendanceDate {
  constructor(
    attendanceId,
    date,
    addition,
    period,
    memo,
    canceled = false,
    fixed = false,
    attendanceStatus = []
  ) {
    this.attendanceId = attendanceId;
    this.date = date;
    this.addition = addition;
    this.period = period;
    this.memo = memo;
    this.canceled = canceled;
    this.fixed = fixed;
    this.attendanceStatus = attendanceStatus;
  }
  get sortedDate() {
    if (this.date) {
      const dt = this.date;
      const y = dt.getFullYear();
      const m = ("00" + (dt.getMonth() + 1)).slice(-2);
      const d = ("00" + dt.getDate()).slice(-2);
      const dtStr = y + m + d;
      if (this.period) {
        return dtStr + this.period;
      } else {
        return dtStr + "0";
      }
    } else {
      return "0";
    }
  }
}
export class CourseAttendance {
  constructor(courseCode, classroom) {
    this.courseCode = courseCode;
    this.classroom = classroom;
    // this.teachers = teachers;
  }
}
