<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <h1>
        <span v-show="!isMobile">日本外国語専門学校</span>
        <span v-show="isMobile">JCFL</span> OE ポータル
      </h1>
      <span v-if="!isProdSite">(テストサイト)(ユーザ種類: {{ userType }})</span>
      <v-spacer></v-spacer>
      <PersonalBar />
      <template v-slot:extension>
        <Menu />
      </template>
    </v-app-bar>

    <v-snackbar bottom :value="showUpdateSnackBar" :timeout="-1" color="info">
      データが更新されました
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="refreshApp" color="primary">
          更新
        </v-btn>
      </template>
    </v-snackbar>

    <v-main>
      <Login v-if="!isLogin" />
      <ClassName v-if="isLogin && (!className || editClassName)" />
      <router-view v-if="isLogin && className && !editClassName" />
      <!-- <router-view v-if="isLogin" /> -->
      <!-- <v-row>
        <v-col cols="12" md="12">
          <EndOfInformation />
        </v-col>
      </v-row> -->
    </v-main>
  </v-app>
</template>

<script>
import classrooms from "../classrooms.json";
// import HeaderMain from "./components/Header/Main";
import Menu from "./components/Header/Menu";
import PersonalBar from "./components/Header/PersonalBar";
import Login from "./components/Login.vue";
import ClassName from "./components/ClassName";
import "./plugins/firebase";
import firebase from "firebase";
// import EndOfInformation from "./components/EndOfInformation";

export default {
  name: "App",

  components: {
    Menu,
    PersonalBar,
    Login,
    ClassName
    // EndOfInformation
  },

  data: () => ({
    //
  }),
  computed: {
    isProdSite() {
      const domain = document.domain;
      return domain === "portal.oe.jcfl.ac.jp";
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showUpdateSnackBar() {
      return this.$store.state.showUpdateSnackBar;
    },
    isLogin() {
      return !!this.$store.state.loginUser;
    },
    className() {
      const name = this.$store.state.loginUser?.className;
      return name;
    },
    editClassName() {
      return this.$store.state.editClassName;
    },
    userType() {
      return this.$store.state.userType;
    }
  },
  methods: {
    refreshApp() {
      window.location.reload();
      this.$store.commit("SET_SHOW_UPDATE_SNACK_BAR", true);
    }
  },
  created() {
    const isEmulating = location.hostname === "localhost";
    if (isEmulating) {
      console.log(isEmulating, "for localhost using firestore as local");
      // firebase.firestore().settings({
      //   host: "localhost:8090",
      //   ssl: false
      // });
      // 以下のエミュレータ設定が良いはずだが、エミュレータとうまく行かないのでステージングでテスト
      // const db = firebase.firestore();
      // db.useEmulator("localhost", 8090);
    }

    // TODO: 初期で読み込まないほうがいい?? 今回はこのままにするか。
    this.$store.commit("SET_CLASSROOMS", classrooms);

    this.$store.commit("DO_LOGIN");
    firebase.auth().onAuthStateChanged(user => {
      if (
        user &&
        (user.email.match(".*@oe[.]jcfl[.]ac[.]jp") ||
          user.email.match(".*@cmscom[.]jp"))
      ) {
        try {
          this.$store.dispatch("getLoginInfo", user);
        } catch {
          // show massage
          this.$store.dispatch("doLogout");
        }
      } else {
        console.log("Can't Login error", user);
        this.$store.dispatch("doLogout");
      }
    });
  },
  updated() {
    this.$store.dispatch("getSubmitted");
    this.$store.dispatch("getSettings");
  }
};
</script>
