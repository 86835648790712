import Vue from "vue";
import Vuex from "vuex";
import {
  Classroom,
  User,
  Submitted,
  CourseAttendance,
  AttendanceDate,
  AttendanceStatus
} from "../models";
import firebase from "firebase";

function toDate(str) {
  var arr = (
    str.substr(0, 4) +
    "/" +
    str.substr(4, 2) +
    "/" +
    str.substr(6, 2)
  ).split("/");
  return new Date(arr[0], arr[1] - 1, arr[2]);
}

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    termId: "2024H2", // 対応する年と期（Firestoreのキーになる）
    termName: "2024年度後期",
    nowAuthAcction: false,
    loginUser: null, // Student
    usennameUpdate: false, // ユーザ名の更新用
    userType: null, // from Custom Claim, coordinator / teacher
    classrooms: [], // List of Classroom
    searchTerm: null,
    searchResults: [], // List of Classroom
    pagination: 1,
    maxShowPage: 12,
    showUpdateSnackBar: false,
    enrolledList: [], // List of Classroom
    submittedList: [], // List of Submitted
    opend: null, // Submitted
    modified: false,
    cardHeight: 300,
    editClassName: false,
    detailShow: true,
    canResearch: false, // CS調査 from firebase settings doc
    teachCourseList: [], // 講師登録されている授業
    isLoadingAttendance: false, // courseAttendanceの取得中
    courseAttendance: null, // CourseAttendance 出欠管理オブジェクト from firebase courses
    studentsAttendance: [], // [Student, ] 出欠用に使う、登録学生のリスト
    attendanceDates: [] // [AttendanceDate, ] 出欠の日付リスト
  },
  mutations: {
    TOGGLE_DETAIL_SHOW: function(state) {
      state.detailShow = !state.detailShow;
    },
    // SET_LOGIN_USER: function(state, userInfo) {
    //   state.loginUser = new Student(userInfo.studentId, userInfo.email);
    // },
    SET_STUDENT_CLASS_NAME: function(state, className) {
      const user = state.loginUser;
      if (user) {
        user.className = className;
        user.modified = true;
        state.modified = true;
      }
    },
    SET_CLASSROOMS: function(state, classrooms) {
      state.classrooms = classrooms.map(
        c =>
          new Classroom(
            c.courseId,
            c.enrollmentCode,
            c.courseState,
            c.courseCode,
            c.name,
            c.section,
            c.descriptionHeading,
            c.description,
            c.room,
            c.className,
            c.teacherId,
            c.teacherName,
            c.coordinatorId,
            c.coordinatorName,
            c.credit,
            c.numberOfStudents,
            c.url,
            c.schedules,
            c.students,
            c.showOrder,
            c.parentCourseCode,
            c.isAttendances == "1" ? true : false,
            c.isCS == "1" ? true : false
          )
      );
    },
    SET_SEARCH_TERM: function(state, term) {
      state.searchTerm = term;
    },
    SET_RESULTS: function(state, results) {
      state.searchResults = results;
    },
    SET_PAGINATION: function(state, num) {
      state.pagination = num;
    },
    SET_SHOW_UPDATE_SNACK_BAR: function(state, show) {
      state.showUpdateSnackBar = show;
    },
    SET_ENROLLED: function(state, classroom) {
      state.enrolledList.push(classroom);
    },
    REMOVE_ENROLLED: function(state) {
      state.enrolledList = [];
    },
    SET_TEACHER: function(state, classroom) {
      state.teachCourseList.push(classroom);
    },
    REMOVE_TEACHERS: function(state) {
      state.teachCourseList = [];
    },
    SET_SUBMITTED: function(state, submitData) {
      const courseId = submitData.courseId;
      const submitted = new Submitted(
        courseId,
        submitData.select1,
        submitData.select2,
        submitData.select3,
        submitData.message
      );
      // state.submittedList.set(courseId, submitted);
      state.submittedList.push(submitted);
    },
    MODIFY_SUBMITTED: function(state, submitData) {
      const courseId = submitData.courseId;
      const submitted = new Submitted(
        courseId,
        submitData.select1,
        submitData.select2,
        submitData.select3,
        submitData.message
      );
      // state.submittedList.delete(courseId);
      const index = state.submittedList.findIndex(s => {
        return s.courseId === courseId;
      });
      if (index > -1) {
        state.submittedList.splice(index, 1);
      }
      // state.submittedList.set(courseId, submitted);
      state.submittedList.push(submitted);
    },
    REMOVE_SUBMITTED: function(state, courseId) {
      // if (state.submittedList.has(courseId)) {
      //   state.submittedList.delete(courseId);
      // }
      const index = state.submittedList.findIndex(s => {
        return s.courseId === courseId;
      });
      if (index > -1) {
        state.submittedList.splice(index, 1);
      }
    },
    DO_LOGIN: function(state) {
      state.nowAuthAcction = true;
    },
    SET_USER: function(state, userObj) {
      state.loginUser = userObj;
      state.nowAuthAcction = false;
    },
    SET_USER_UPDATE_FLAG: function(state, update) {
      state.usennameUpdate = update;
    },
    SET_USER_CLASS_NAME: function(state, className) {
      const student = state.loginUser;
      student.className = className;
      student.modified = true;
      state.editClassName = false;
    },
    EDOT_USER_CLASS_NAME: function(state) {
      state.editClassName = true;
    },
    CANCEL_EDOT_USER_CLASS_NAME: function(state) {
      state.editClassName = false;
    },
    REMOVE_USER: function(state) {
      state.loginUser = null;
      state.nowAuthAcction = false;
    },
    SET_USER_TYPE: function(state, userType) {
      state.userType = userType;
    },
    DO_SETTINGS: function(state, settings) {
      state.canResearch = settings.canResearch;
    },
    SET_COURSE_DATA: function(state, courseData) {
      const courseCode = courseData.courseCode;
      const classroom = courseData.classroom;
      // const teachers = courseData.teachers;
      const courseAttendance = new CourseAttendance(
        courseCode,
        classroom
        // teachers
      );
      state.courseAttendance = courseAttendance;
    },
    ADD_ATTENDANCE: function(state, attendanceData) {
      const attendanceId = attendanceData.attendanceId;
      const attendanceIndex = state.attendanceDates.findIndex(a => {
        return a.attendanceId == attendanceId;
      });
      if (attendanceIndex > -1) {
        state.attendanceDates.splice(attendanceIndex, 1);
      }
      state.attendanceDates.push(
        new AttendanceDate(
          attendanceId,
          toDate(attendanceData.date),
          attendanceData.addition,
          attendanceData.period,
          attendanceData.memo,
          attendanceData.canceled,
          attendanceData.fixed
        )
      );
    },
    ADD_ATTENDANCE_STUDENT: function(state, studentData) {
      const attendanceId = studentData.attendanceId;
      const student = new AttendanceStatus(
        studentData.studentId,
        studentData.status,
        studentData.late,
        studentData.left,
        studentData.fixed,
        studentData.sent,
        studentData.comment
      );
      const attendanceDates = state.attendanceDates.filter(a => {
        return a.attendanceId == attendanceId;
      });
      if (attendanceDates.length > 0) {
        const attendanceDate = attendanceDates[0];
        const studentIndex = attendanceDate.attendanceStatus.findIndex(s => {
          return s.studentId == studentData.studentId;
        });
        if (studentIndex > -1) {
          attendanceDate.attendanceStatus.splice(studentIndex, 1);
        }
        attendanceDate.attendanceStatus.push(student);
      }
    },
    REMOVE_COURSE_ATTENDANCE: function(state) {
      state.courseAttendance = null;
      state.studentsAttendance = [];
      state.attendanceDates = [];
    },
    ADD_STUDENTS_ATTENDANCE: function(state, enrolledData) {
      const studentId = enrolledData.uid;
      const student = new User(
        studentId,
        enrolledData.email,
        enrolledData.type,
        enrolledData.name,
        enrolledData.enName,
        enrolledData.kana,
        enrolledData.active
      );
      const index = state.studentsAttendance.findIndex(s => {
        return s.uid == studentId;
      });
      if (index > -1) {
        state.studentsAttendance.splice(index, 1);
      }
      state.studentsAttendance.push(student);
    },
    REMOVE_STUDENTS_ATTENDANCE: function(state, studentId) {
      const index = state.studentsAttendance.findIndex(s => {
        return s.studentId == studentId;
      });
      if (index > -1) {
        state.studentsAttendance.splice(index, 1);
      }
    }
  },
  actions: {
    // TODO: 検索方式をfirebase問い合わせに変更
    async doSearch({ commit }, sterm) {
      commit("SET_SEARCH_TERM", sterm);
      const result = this.state.classrooms.filter(c => {
        let hasSTeam = true;
        sterm
          .replace("　", " ")
          .split(" ")
          .map(t => {
            if (c.searchableText.search(t.toLowerCase()) == -1) {
              hasSTeam = false;
            }
          });
        return hasSTeam;
      });
      commit("SET_RESULTS", result);
    },
    async getLoginInfo({ dispatch, commit }, user) {
      const uid = user.uid;
      const email = user.email;
      await firebase
        .firestore()
        .collection("users")
        .doc(email)
        .get()
        .then(s => {
          if (s.exists) {
            const userData = s.data();
            const uidData = userData.uid;
            if (!uidData) {
              const userBase = {
                uid,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp()
              };
              firebase
                .firestore()
                .collection(`users`)
                .doc(email)
                .update(userBase);
            }
            const userObj = new User(
              uid,
              email,
              userData.type,
              userData.name,
              userData.enName,
              userData.kana,
              true,
              userData.className
            );
            commit("SET_USER", userObj);
            commit("SET_USER_UPDATE_FLAG", !!userData.update);
            if (this.state.usennameUpdate) {
              dispatch("updateEnrolledUserName");
            }
            dispatch("getEnrolled");
            dispatch("getTeachCourses");
          } else {
            firebase
              .firestore()
              .collection("users")
              .doc(email)
              .set({
                uid: uid,
                email: email,
                update: false,
                createdAt: firebase.firestore.FieldValue.serverTimestamp()
              });
            const userObj = new User(uid, email);
            commit("SET_USER", userObj);
            dispatch("getEnrolled");
          }
        })
        .catch(error => {
          console.log("Error getting document:", error);
        });
      dispatch("watchUserType", user);
    },
    async watchUserType({ commit }, user) {
      const email = user.email;
      firebase
        .firestore()
        .collection("users")
        .doc(email)
        .onSnapshot(() => {
          let userType;
          user.getIdTokenResult(true).then(result => {
            if (result.claims.coordinator) {
              userType = "coordinator";
            } else if (result.claims.teacher) {
              userType = "teacher";
            } else {
              userType = "student";
            }
            if (userType) {
              commit("SET_USER_TYPE", userType);
              console.log("SET_USER_TYPE: " + userType);
            }
          });
        });
    },
    updateEnrolledUserName({ state }) {
      // console.log("start");
      const uid = state.loginUser.uid;
      firebase
        .firestore()
        .collectionGroup(`${this.state.termId}_enrolled`)
        .where("uid", "==", uid)
        .get()
        .then(enrolles => {
          enrolles.forEach(enrolled => {
            if (enrolled.data().active) {
              const courseCode = enrolled.ref.parent.parent.id;
              if (
                (!enrolled.data().name || this.state.usennameUpdate) &&
                this.state.loginUser.name
              ) {
                // console.log(
                //   "update user Name: ",
                //   this.state.usennameUpdate,
                //   this.state.loginUser.name
                // );
                let courseRef = firebase
                  .firestore()
                  .collection(`${this.state.termId}_courses`)
                  .doc(courseCode);
                courseRef
                  .collection(`${this.state.termId}_enrolled`)
                  .doc(uid)
                  .update({
                    ...this.state.loginUser,
                    updatedAt: firebase.firestore.FieldValue.serverTimestamp()
                  });
              }
            }
          });
        });
      // commit("SET_USER_UPDATE_FLAG", false);
      if (this.state.usennameUpdate) {
        const userBase = {
          update: false,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        };
        firebase
          .firestore()
          .collection(`users`)
          .doc(this.state.loginUser.email)
          .update(userBase);
      }
    },
    async getEnrolled({ commit }) {
      const uid = this.state.loginUser.uid;
      firebase
        .firestore()
        .collectionGroup(`${this.state.termId}_enrolled`)
        .where("uid", "==", uid)
        .onSnapshot(enrolles => {
          commit("REMOVE_ENROLLED"); // todo: 少し不安。一旦消してから全て入れ直し？
          enrolles.forEach(enrolled => {
            if (enrolled.data().active) {
              const courseCode = enrolled.ref.parent.parent.id;
              const result = this.state.classrooms.filter(c => {
                return !!(courseCode == c.courseCode);
              });
              if (result.length > 0) {
                commit("SET_ENROLLED", result[0]);
              }
            }
          });
        });
    },
    async getTeachCourses({ commit }) {
      const uid = this.state.loginUser.uid;
      firebase
        .firestore()
        .collectionGroup(`${this.state.termId}_teachers`)
        .where("uid", "==", uid)
        .onSnapshot(teachers => {
          commit("REMOVE_TEACHERS");
          teachers.forEach(teacher => {
            if (teacher.data().active) {
              const courseCode = teacher.ref.parent.parent.id;
              const result = this.state.classrooms.filter(c => {
                return !!(courseCode == c.courseCode);
              });
              if (result.length > 0) {
                commit("SET_TEACHER", result[0]);
              }
            }
          });
        });
    },
    async getSubmitted({ commit }) {
      const uid = this.state.loginUser?.uid;
      // const email = this.state.loginUser?.email;
      if (uid) {
        firebase
          .firestore()
          .collection("submitted")
          .doc(uid)
          .get()
          .then(s => {
            if (!s.exists) {
              firebase
                .firestore()
                .collection("submitted")
                .doc(uid)
                .set({
                  createdAt: firebase.firestore.FieldValue.serverTimestamp()
                });
            }
          });
        firebase
          .firestore()
          .collection(`submitted/${uid}/answers`)
          .onSnapshot(answers => {
            // answers.docs.map(answer => {
            answers.docChanges().forEach(change => {
              const submitData = {
                courseId: change.doc.id,
                ...change.doc.data()
              };
              if (change.type === "added") {
                // console.log("New : ", change.doc.data());
                commit("MODIFY_SUBMITTED", submitData);
              }
              if (change.type === "modified") {
                // console.log("Modified : ", change.doc.data());
                commit("MODIFY_SUBMITTED", submitData);
              }
              if (change.type === "removed") {
                // console.log("Removed : ", change.doc.data());
                commit("REMOVE_SUBMITTED", change.doc.id);
              }
            });
          });
      }
    },
    async getSettings({ commit }) {
      firebase
        .firestore()
        .collection("settings")
        .doc("setting")
        .onSnapshot(setting => {
          commit("DO_SETTINGS", setting.data());
        });
    },
    doLogout({ commit }) {
      commit("REMOVE_USER");
      firebase.auth().signOut();
    },
    async setClassName({ commit }, name) {
      commit("SET_USER_CLASS_NAME", name);
      const email = this.state.loginUser.email;
      await firebase
        .firestore()
        .collection("users")
        .doc(email)
        .update({
          className: name,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        });
      // dispatch("getEnrolled");
    },
    async doSubmit({ state }, data) {
      const uid = state.loginUser.uid;
      // const email = state.loginUser.email;
      const courseCode = data.courseCode;
      await firebase
        .firestore()
        .collection(`submitted/${uid}/answers`)
        .doc(courseCode)
        .set({
          ...data.answer,
          createdAt: firebase.firestore.FieldValue.serverTimestamp()
        });
    },
    async doEnroll({ state }, courseCode) {
      const uid = state.loginUser.uid;
      // const email = state.loginUser.email;
      // const enrolledList = state.enrolledList;
      // const updateCourseCodes = [
      //   ...enrolledList.map(c => c.courseCode),
      //   courseCode
      // ];
      let courseRef = firebase
        .firestore()
        .collection(`${this.state.termId}_courses`)
        .doc(courseCode);
      await courseRef.get().then(c => {
        if (c.data()) {
          let enrolled = courseRef
            .collection(`${this.state.termId}_enrolled`)
            .doc(uid);
          enrolled.get().then(e => {
            if (e.data()) {
              enrolled.update({
                ...state.loginUser,
                active: true,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp()
              });
            } else {
              enrolled.set({
                ...state.loginUser,
                active: true,
                createdAt: firebase.firestore.FieldValue.serverTimestamp()
              });
            }
          });
        } else {
          courseRef
            .collection(`${this.state.termId}_enrolled`)
            .doc(uid)
            .set({
              ...state.loginUser,
              active: true,
              createdAt: firebase.firestore.FieldValue.serverTimestamp()
            });
          // console.log("set Enroll for No courseCode: ", courseCode);
        }
      });
    },
    async doUnEnroll({ state }, courseCode) {
      const uid = state.loginUser.uid;
      await firebase
        .firestore()
        .collection(`${this.state.termId}_courses`)
        .doc(courseCode)
        .collection(`${this.state.termId}_enrolled`)
        .doc(uid)
        .update({
          active: false,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        });
    },
    async doAssignTeacher({ state }, courseCode) {
      const uid = state.loginUser.uid;
      let courseRef = firebase
        .firestore()
        .collection(`${this.state.termId}_courses`)
        .doc(courseCode);
      await courseRef.get().then(c => {
        if (c.data()) {
          let teachers = courseRef
            .collection(`${this.state.termId}_teachers`)
            .doc(uid);
          teachers.get().then(e => {
            if (e.data()) {
              teachers.update({
                ...state.loginUser,
                active: true,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp()
              });
            } else {
              teachers.set({
                ...state.loginUser,
                active: true,
                createdAt: firebase.firestore.FieldValue.serverTimestamp()
              });
            }
          });
        } else {
          courseRef
            .collection(`${this.state.termId}_teachers`)
            .doc(uid)
            .set({
              ...state.loginUser,
              active: true,
              createdAt: firebase.firestore.FieldValue.serverTimestamp()
            });
          // console.log("Set teacher for No courseCode: ", courseCode);
        }
      });
    },
    async doUnAssignTeacher({ state }, courseCode) {
      const uid = state.loginUser.uid;
      await firebase
        .firestore()
        .collection(`${this.state.termId}_courses`)
        .doc(courseCode)
        .collection(`${this.state.termId}_teachers`)
        .doc(uid)
        .update({
          active: false,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        });
    },
    async getCourseAttendance({ commit }, courseCode) {
      this.state.isLoadingAttendance = true;
      commit("REMOVE_COURSE_ATTENDANCE");
      let classroom;
      const classrooms = this.state.classrooms.filter(c => {
        return !!(courseCode == c.courseCode);
      });
      if (classrooms.length > 0) {
        classroom = classrooms[0];
      } else {
        return;
      }
      const courseData = { courseCode, classroom };
      commit("SET_COURSE_DATA", courseData);
    },
    async getCourseAttendanceEnrolled({ commit }, courseCode) {
      this.state.isLoadingAttendance = true;
      firebase
        .firestore()
        .collection(
          `${this.state.termId}_courses/${courseCode}/${this.state.termId}_enrolled`
        )
        .onSnapshot(users => {
          users.forEach(user => {
            const userObj = user.data();
            commit("ADD_STUDENTS_ATTENDANCE", userObj);
          });
          this.state.isLoadingAttendance = false;
        });
    },
    async getCourseAttendanceDetail({ commit }, data) {
      const courseCode = data.courseCode;
      const studentId = data.studentId;

      this.state.isLoadingAttendance = true;
      firebase
        .firestore()
        .collection(`${this.state.termId}_courses/${courseCode}/attendances`)
        .onSnapshot(attendance => {
          attendance.forEach(doc => {
            const attendanceData = {
              attendanceId: doc.id,
              ...doc.data()
            };
            commit("ADD_ATTENDANCE", attendanceData);

            if (studentId) {
              doc.ref
                .collection("students")
                .doc(studentId)
                .onSnapshot(student => {
                  if (student.exists) {
                    const studentData = {
                      attendanceId: doc.id,
                      studentId: student.id,
                      ...student.data()
                    };
                    commit("ADD_ATTENDANCE_STUDENT", studentData);
                  }
                });
            } else {
              doc.ref.collection("students").onSnapshot(student => {
                student.forEach(sDoc => {
                  const studentData = {
                    attendanceId: doc.id,
                    studentId: sDoc.id,
                    ...sDoc.data()
                  };
                  commit("ADD_ATTENDANCE_STUDENT", studentData);
                });
              });
            }
          });
          this.state.isLoadingAttendance = false;
        });
    },
    removeCourseAttendance({ commit }) {
      commit("REMOVE_COURSE_ATTENDANCE");
    },
    async makeAttendanceDate({ state }, data) {
      // commit("MAKE_ATTENDANCE_DATE", data)
      const courseCode = data.classroom.courseCode;
      const dateStr = data.date.replace(/-/g, "");
      const addition = data.type === "addition" ? true : false;
      const period = data.period;
      const memo = data.memo;
      await firebase
        .firestore()
        .collection(`${state.termId}_courses/${courseCode}/attendances`)
        .add({
          date: dateStr,
          addition: addition,
          period: period,
          memo: memo,
          createdAt: firebase.firestore.FieldValue.serverTimestamp()
        });
    },
    async modifyAttendanceDate({ state }, data) {
      const attendanceId = data.attendanceId;
      const courseCode = data.classroom.courseCode;
      const dateStr = data.date.replace(/-/g, "");
      const addition = data.type === "addition" ? true : false;
      const period = data.period;
      const memo = data.memo;
      await firebase
        .firestore()
        .collection(`${state.termId}_courses/${courseCode}/attendances`)
        .doc(attendanceId)
        .update({
          date: dateStr,
          addition: addition,
          period: period,
          memo: memo,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        });
    },
    async doAttendanceStatusModify({ state }, data) {
      const courseCode = data.courseCode;
      const attendanceId = data.attendanceId;
      const studentId = data.studentId;
      const statusId = data.statusId;
      const status = data.status;
      const late = data.late;
      const left = data.left;

      let updateData;
      if (statusId == "attended") {
        // 出席
        if (status === 1) {
          updateData = {
            status: null,
            late: null,
            left: null
          };
        } else {
          updateData = {
            status: 1
          };
        }
      } else if (statusId == "absence") {
        // 欠席
        if (status === 2) {
          updateData = {
            status: null
          };
        } else {
          updateData = {
            status: 2,
            late: false,
            left: false
          };
        }
      } else if (statusId == "late") {
        // 遅刻
        if (late) {
          updateData = {
            late: false
          };
        } else {
          updateData = {
            status: 1,
            late: true
          };
        }
      } else if (statusId == "left") {
        // 早退
        if (left) {
          updateData = {
            left: false
          };
        } else {
          updateData = {
            status: 1,
            left: true
          };
        }
      }
      const studentRef = firebase
        .firestore()
        .collection(
          `${state.termId}_courses/${courseCode}/attendances/${attendanceId}/students`
        )
        .doc(studentId);
      await studentRef.get().then(s => {
        if (!s.exists) {
          studentRef.set({
            fixed: false,
            sent: false,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            createdUser: state.loginUser.uid,
            ...updateData
          });
        } else {
          s.ref.update({
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            updateUser: state.loginUser.uid,
            ...updateData
          });
        }
      });
    },
    async doAttendanceStatusComment({ state }, data) {
      const courseCode = data.courseCode;
      const attendanceId = data.attendanceId;
      const studentId = data.studentId;
      const memo = data.memo;

      let updateData;
      updateData = {
        comment: memo
      };
      const studentRef = firebase
        .firestore()
        .collection(
          `${state.termId}_courses/${courseCode}/attendances/${attendanceId}/students`
        )
        .doc(studentId);
      await studentRef.get().then(s => {
        if (!s.exists) {
          studentRef.set({
            fixed: false,
            sent: false,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            createdUser: state.loginUser.uid,
            ...updateData
          });
        } else {
          s.ref.update({
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            updateUser: state.loginUser.uid,
            ...updateData
          });
        }
      });
    },
    async doFixAttendanceDate({ state }, data) {
      const userUid = state.loginUser.uid;
      const termId = state.termId;
      const courseCode = data.courseCode;
      const attendanceDateObj = data.attendanceDate;
      const attendanceDate = attendanceDateObj.attendanceId;
      const fixed = data.fixed;
      const batch = firebase.firestore().batch();
      const attendanceDateRef = firebase
        .firestore()
        .collection(`${termId}_courses/${courseCode}/attendances`)
        .doc(attendanceDate);
      batch.update(attendanceDateRef, {
        fixed: fixed,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      });
      const studentsRef = await firebase
        .firestore()
        .collection(
          `${termId}_courses/${courseCode}/attendances/${attendanceDate}/students`
        )
        .get();
      studentsRef.docs.forEach(s => {
        batch.update(s.ref, {
          fixed: fixed,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updateUser: userUid
        });
      });
      await batch.commit();
    },
    async doCancelAttendanceDate({ state }, data) {
      // const userUid = state.loginUser.uid;
      const termId = state.termId;
      const courseCode = data.courseCode;
      const attendanceDateObj = data.attendanceDate;
      const attendanceDate = attendanceDateObj.attendanceId;
      const canceled = data.canceled;
      const batch = firebase.firestore().batch();
      const attendanceDateRef = firebase
        .firestore()
        .collection(`${termId}_courses/${courseCode}/attendances`)
        .doc(attendanceDate);
      batch.update(attendanceDateRef, {
        canceled: canceled,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      });
      await batch.commit();
    },
    async doUnEnrollByTeacher({ state }, data) {
      const courseCode = data.courseCode;
      const studentId = data.studentId;
      const active = data.active;
      const userUid = state.loginUser.uid;
      await firebase
        .firestore()
        .collection(`${this.state.termId}_courses`)
        .doc(courseCode)
        .collection(`${this.state.termId}_enrolled`)
        .doc(studentId)
        .update({
          active: active,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updateUser: userUid
        });
    }
  },
  modules: {}
});
