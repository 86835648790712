import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  // customVariables: ["~/assets/sass/variables.scss"],
  // treeShake: true,
  theme: {
    themes: {
      light: {
        primary: "#3f51b5",
        secondary: "#EF6C00",
        accent: "#0277BD",
        info: "#2196F3"
      }
    }
  },
  icons: {
    iconfont: "mdiSvg" // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  }
});
