<template>
  <v-tabs align-with-title center-active>
    <!-- <v-tab to="/">Home</v-tab>
    <v-tab to="/about">About</v-tab> -->
    <v-tab to="/">授業検索</v-tab>
    <!-- <v-tab to="/class-timetable">クラス時間割</v-tab> -->
    <v-tab to="/enrolled">登録済み</v-tab>
    <v-tab to="/timetable">時間割</v-tab>
    <!-- <v-tab to="/submitted">CS調査回答済み</v-tab> -->
    <v-tab to="/attendance">出欠確認</v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: "Menu",

  components: {},

  data: () => ({
    //
  })
};
</script>
